import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "components/anti/cover/cover"

export const MembershipCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <div ref={trigger} className="cover-full ">
      <Cover
        variant="basic"
        theme="dark"
        imgMd={data.image.sourceUrl}
        img={data.mobileImage.sourceUrl}
        imgHeight="h-400px h-md-500px"
        labelClassName={`${anim(2)} mb-2`}
        titleClassName={`${anim(3)} font-weight-normal mb-0`}
        label={data.label}
        title={data.title}
        className="cover-membership animated fadeInUp"
        contentMaxWidth="mw-md-550px mw-lg-650px pt-5"
      />
    </div>
  )
}
