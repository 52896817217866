import React from "react"

import { Button } from "components/anti/buttons/buttons"

const MembershipTab = ({ activeState, setActiveState }) => {
  return (
    <section className="sc-membership-tab">
      <div className="row row-0">
        <div className="col-6">
          <Button
            variant="link"
            onClick={() => setActiveState("our-tiers")}
            className={`btn-block ${
              activeState === "our-tiers" ? "active" : ""
            }`}
          >
            Our Tiers
          </Button>
        </div>
        <div className="col-6">
          <Button
            variant="link"
            onClick={() => setActiveState("aman-miles")}
            className={`btn-block ${
              activeState === "aman-miles" ? "active" : ""
            }`}
          >
            Aman Miles
          </Button>
        </div>
      </div>
    </section>
  )
}

export default MembershipTab
