import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"
import { MembershipCover } from "components/pages/membership/cover"
import { Features } from "components/global/features/features"
import { MembershipTiers } from "components/pages/membership/tiers"
import MembershipTab from "./tab"
import MembershipAmanMiles from "./aman-miles"

const Membership = ({ pageContext, path }) => {
  const lang = pageContext.langKey
  const data = useStaticQuery(GET_MEMBERSHIP_DETAILS)
  const block = data?.wordPress?.page?.membershipPage
  const tiers = data?.wordPress?.tiers?.nodes

  const [activeState, setActiveState] = useState("our-tiers")

  return (
    <Layout lang={lang} path={path}>
      <Seo title="Membership" />
      <MembershipCover data={block.cover} />
      <MembershipTab
        activeState={activeState}
        setActiveState={setActiveState}
      />
      {activeState === "our-tiers" && (
        <>
          <Features data={block.features} className="sc-membership-features" />
          <MembershipTiers tiers={tiers} img={block?.membership?.cover} />
        </>
      )}
      {activeState === "aman-miles" && (
        <MembershipAmanMiles data={block?.amanMiles} />
      )}
    </Layout>
  )
}

export default Membership

const GET_MEMBERSHIP_DETAILS = graphql`
  query MembershipPage {
    wordPress {
      page(id: "membership", idType: URI) {
        membershipPage {
          cover {
            label
            title
            image {
              sourceUrl
            }
            mobileImage {
              sourceUrl
            }
          }
          features {
            label
            features {
              downloadButton
              text
              buttons {
                text
                url
              }
              image {
                sourceUrl
              }
            }
          }
          membership {
            cover {
              sourceUrl
            }
          }
          amanMiles {
            title
            text
            list {
              text
            }
            image {
              mediaItemUrl
            }
          }
        }
      }
      tiers(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          title
          slug
          tier {
            cover {
              text
              title
              comingSoon
              buttons {
                url
                text
              }
            }
            benefits {
              list {
                text
              }
              category {
                name
                slug
              }
            }
            tierBenefits {
              text
            }
          }
        }
      }
    }
  }
`
