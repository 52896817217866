import React, { useState } from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Image } from "../../anti/image/image"
import { NavTabs, TabContent, TabPane } from "components/anti/tabs/tabs"
import { Button } from "components/anti/buttons/buttons"
import { Accordion } from "components/anti/accordion/accordion"

export const MembershipTiers = ({ tiers, img }) => {
  const [trigger, anim] = useScrollAnim()

  const dataTabs = [
    ...tiers.map(data => ({ name: data.title, target: data.slug })),
  ]

  return (
    <section
      className="py-main sc-membership-tiers position-relative bg-dark"
      ref={trigger}
    >
      <div className="bg-wrapper">
        <Image
          src={img.sourceUrl}
          className="bg-partners"
          ratio="r-16-9 h-300px h-md-450px"
          alt="bg"
        />
        <div className="bg-gradient h-100 w-100" />
      </div>
      <div className="container">
        <p className={`${anim(2)} caption`}>Tier Membership</p>
        <NavTabs
          variant="tabs"
          scrollable
          data={dataTabs}
          className={`${anim(3)}`}
        />
        <TabContent>
          {tiers.map((data, i) => {
            const benefitsList = data?.tier?.tierBenefits
            return (
              <TabPane
                id={data.slug}
                className={`${anim(1 + i)} ${i === 0 ? "active" : ""}`}
                key={i}
              >
                {data.tier.cover.comingSoon ? (
                  <>
                    <div className="description"></div>
                    <div className="membership-tiers-benefits w-md-550px pb-5">
                      <div className="pb-5">
                        <h2>
                          Benefits Kami akan segera hadir dalam beberapa level.
                        </h2>
                        <p>
                          Semakin tinggi levelmu maka keuntungan yang kamu
                          dapatkan juga semakin banyak nantinya, tunggu
                          kehadirannya!
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="description">
                      <p className="mb-0">{data.tier.cover.text}</p>
                      <Button variant="link" link={data.tier.cover.buttons.url}>
                        {data.tier.cover.buttons.text}
                      </Button>
                    </div>
                    <div className="membership-tiers-benefits">
                      {benefitsList?.length > 0 && (
                        <h4 className="mb-4">Benefits</h4>
                      )}
                      <ul className="list-unstyled list">
                        {benefitsList?.map((li, i) => (
                          <li key={i} className="mb-3">
                            {li.text}
                          </li>
                        ))}
                      </ul>
                      {/* {benefitsList.map((list, i) => {
                        return (
                          <Accordion
                            expandIconPlus
                            variant="boxless"
                            title={list.category.name}
                            expandedKey={list?.category?.slug}
                            key={i}
                          >
                            <ul className="list-unstyled list">
                              {list.list.map((li, i) => (
                                <li key={i}>{li.text}</li>
                              ))}
                            </ul>
                          </Accordion>
                        )
                      })} */}
                    </div>
                  </>
                )}
              </TabPane>
            )
          })}
        </TabContent>
      </div>
    </section>
  )
}
