import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import floatGfx from "../../../assets/img/floating-3.svg"

const MembershipAmanMiles = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-benefits" ref={trigger}>
      <div className="container">
        <div className="row row-5">
          <div className={`col-lg-6 col-text `}>
            <h2 className={`h1 ${anim(1)}`}>{data?.title}</h2>
            <p className={`${anim(2)} pr-md-5`}>{data?.text}</p>
            <ul className="list">
              {data?.list.map((data, i) => (
                <li key={i} className={`${anim(4 + i)} mb-3`}>
                  {data.text}
                </li>
              ))}
            </ul>
          </div>
          <div className={`col-lg-6 col-image`}>
            <img
              src={data?.image?.mediaItemUrl}
              className={`${anim(5)} img-benefits img-fluid `}
              alt={data?.title}
            />
            <img
              src={floatGfx}
              className={`${anim(6)} gfx-wrapper`}
              alt={data?.title}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MembershipAmanMiles
